import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Adam Worley`}</h1>
    <p>{`I'm a seasoned software developer with over a decade of experience. My primary expertise lies in C#, but I have also delved into infrastructure development using Terraform, Container orchestrators and GO tooling, as well as exploring various front-end frameworks.`}</p>
    <p>{`Currently, I am proud to be a part of the team at `}<a parentName="p" {...{
        "href": "https://www.netwealth.com"
      }}>{`netwealth`}</a>{`, a leading fintech company specializing in pensions and investments utilising the hybrid of digital tools and professional advisers. At netwealth, I contribute my skills and knowledge to develop innovative solutions and enhance the user experience.`}</p>
    <p>{`With a strong passion for technology and a dedication to delivering high-quality software, I continuously strive to expand my skill set and stay up-to-date with the latest industry trends.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      