import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Section`}</h1>
    <p>{`I'm a tech enthusiast with a passion for coding and exploring all things techy. When I'm not immersed in software development, I enjoy engaging in activities that help me relax and recharge.`}</p>
    <p>{`Indoor rock climbing is my go-to activity, where I challenge myself both physically and mentally while conquering various climbing routes. It's a thrilling experience that keeps me energized and motivated.`}</p>
    <p>{`In addition to climbing, I find balance and inner calm through practising yoga. The meditative aspects of yoga allow me to rejuvenate and find clarity in a busy world.`}</p>
    <p>{`During my downtime, I love watching movies, playing games, and savouring good moments with a refreshing beverage or a cup of coffee.`}</p>
    <p>{`Sports-wise, I have a genuine interest in badminton, enjoying friendly matches that keep me active and engaged. And when I want to connect with nature and seek tranquillity, stand-up paddleboarding (SUP) provides the perfect escape on calm waters.`}</p>
    <p>{`By embracing this blend of technology, indoor rock climbing, leisure activities, and sports, I strive to maintain a well-rounded lifestyle that fuels my creativity and overall well-being.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      