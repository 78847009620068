import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projects`}</h1>
    <h2>{`Projects`}</h2>
    <ProjectCard title="Crawley Dog Walkers" link="<https://crawleydogwalkers.com>" bg="linear-gradient(to right, #ffcb2d 0%, #ffe238 100%)" mdxType="ProjectCard">
  This website is for my wife's dog walking business
    </ProjectCard>
    <ProjectCard title="Netwealth" link="<https://www.netwealth.com>" bg="linear-gradient(to left, #112E51 0%, #21599D 100%)" mdxType="ProjectCard">
  Currently employed by Netwealth
    </ProjectCard>
    <ProjectCard title="AJW Group" link="<https://www.ajw-group.com>" bg="linear-gradient(to left, #2B0F54 0%, #521CA0 100%)" mdxType="ProjectCard">
  My previous employer
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      